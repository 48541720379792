import { format } from "date-fns";
import ro from "date-fns/locale/ro";

export default {
    methods: {
        formatDate(date, formatString = "dd.MM.yyyy") {
            if (date) {
                let d = null;
                const isoDate = new Date(date);

                try {
                    d = format(isoDate, formatString, { locale: ro });
                } catch {
                    d = format(isoDate, formatString, { locale: ro });
                }

                return d;
            }
        }
    }
};
